import { ArrowDownward } from "@mui/icons-material";
import { Box, Button, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function Index() {
  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        textAlign="center"
        gap={5}
      >
        <Typography variant="h1" fontSize={"44px"}>
          Pintar Kredit
        </Typography>
        <Typography
          variant="body1"
          color="text.primary"
          sx={{ marginBottom: "20px" }}
        >
          Aplikasi yang membantu pengguna menganalisis pengajuan kredit secara
          mandiri sebelum mengajukannya ke lembaga keuangan.
          <br />
          <br />
          <small>
            *Semua informasi yang anda masukan tidak akan pernah disimpan dalam
            bentuk apapun.
          </small>
        </Typography>
        <Box marginTop="10px">
          <Link to="/app">
            <Button variant="contained">Coba Sekarang</Button>
          </Link>
        </Box>
        <Box position="absolute" bottom="10%">
          <ArrowDownward />
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" gap={8} mb={10}>
        <Box>
          <Typography variant="h3" fontSize={"28px"}>
            Latar Belakang
          </Typography>
          <Typography variant="body1">
            Latar belakang pengembangan aplikasi Pintar Kredit adalah
            meningkatnya ketersediaan pinjaman melalui bank dan aplikasi
            pinjaman online, yang dapat menyebabkan risiko bagi konsumen jika
            tidak dikelola dengan bijak.
          </Typography>
        </Box>
        <Box>
          <Typography variant="h3" fontSize={"28px"}>
            Solusi
          </Typography>
          <Typography variant="body1">
            Aplikasi pintar kredit hadir sebagai solusi untuk memberikan
            pengguna alat yang membantu mereka menganalisis dan mengelola
            pinjaman mereka dengan lebih baik, sehingga mengurangi potensi
            masalah keuangan yang dapat timbul akibat pinjaman yang tidak
            terkendali.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default Index;
