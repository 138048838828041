import Container from "@mui/material/Container";
import "../styles/app.css";
import {
  AppBar,
  Box,
  Button,
  LinearProgress,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";

const app = initializeApp({
  apiKey: "AIzaSyAc7V6noK3q9s0mhuCP9FBdevebAU40eG4",
  authDomain: "pintar-kredit-1df31.firebaseapp.com",
  projectId: "pintar-kredit-1df31",
  storageBucket: "pintar-kredit-1df31.appspot.com",
  messagingSenderId: "165944845417",
  appId: "1:165944845417:web:bab33aa1955d0013216ef8",
  measurementId: "G-CDMZ9FYK8R",
});
const functions = getFunctions(app);

interface DataPeminjam {
  pekerjaan: string;
  jenis_usaha_or_tempat_bekerja: string;
  penghasilan: string;
  pinjaman: string;
  angsuran: string;
  status: string;
  tanggungan: string;
  jaminan: string;
  nilai_jaminan: string;
  tujuan_pinjaman: string;
  bunga: string;
  catatan: string;
}

interface ReqInterface {
  prompt: string;
}

export interface IOpenAIRes {
  id: string;
  object: string;
  created: number;
  model: string;
  choices: Choice[];
  usage: Usage;
}

export interface Choice {
  index: number;
  message: Message;
  finish_reason: string;
}

export interface Message {
  role: string;
  content: string;
}

export interface Usage {
  prompt_tokens: number;
  completion_tokens: number;
  total_tokens: number;
}

function App() {
  const [step, setStep] = useState<"start" | "finish">("start");
  const [dataPeminjam, setDataPeminjam] = useState<DataPeminjam>({
    pekerjaan: "",
    jenis_usaha_or_tempat_bekerja: "",
    penghasilan: "",
    pinjaman: "",
    angsuran: "",
    status: "",
    tanggungan: "",
    jaminan: "",
    nilai_jaminan: "",
    tujuan_pinjaman: "",
    bunga: "",
    catatan: "",
  });
  const [res, setRes] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingPercentage, setLoadingPercentage] = useState<number>(0);

  const analyzeData = async () => {
    setIsLoading(true);
    setLoadingPercentage(0);

    setTimeout(() => {
      setLoadingPercentage(10);
    }, 1000);

    setTimeout(() => {
      setLoadingPercentage(20);
    }, 2000);

    setTimeout(() => {
      setLoadingPercentage(30);
    }, 3000);

    setTimeout(() => {
      setLoadingPercentage(40);
    }, 4000);

    setTimeout(() => {
      setLoadingPercentage(60);
    }, 6000);

    setTimeout(() => {
      setLoadingPercentage(70);
    }, 9000);

    setTimeout(() => {
      setLoadingPercentage(80);
    }, 12000);

    setTimeout(() => {
      setLoadingPercentage(90);
    }, 15000);

    setTimeout(() => {
      setLoadingPercentage(92);
    }, 18000);

    setTimeout(() => {
      setLoadingPercentage(94);
    }, 21000);

    setTimeout(() => {
      setLoadingPercentage(96);
    }, 24000);

    setTimeout(() => {
      setLoadingPercentage(98);
    }, 27000);

    setTimeout(() => {
      setLoadingPercentage(99);
    }, 30000);

    const generateTextFunction = httpsCallable<ReqInterface, IOpenAIRes>(
      functions,
      "generateText"
    );

    try {
      const result = await generateTextFunction({
        prompt: `Pekerjaan: ${dataPeminjam.pekerjaan}\nJenis Usaha / Tempat Bekerja: ${dataPeminjam.jenis_usaha_or_tempat_bekerja}\nPenghasilan: ${dataPeminjam.penghasilan}\nPinjaman: ${dataPeminjam.pinjaman}\nLama Angsuran: ${dataPeminjam.angsuran}\nStatus: ${dataPeminjam.status}\nTanggungan: ${dataPeminjam.tanggungan}\nJaminan: ${dataPeminjam.jaminan}\nNilai Jaminan: ${dataPeminjam.nilai_jaminan}\nTujuan Pinjaman: ${dataPeminjam.tujuan_pinjaman}\nBunga: ${dataPeminjam.bunga}\nCatatan: ${dataPeminjam.catatan}\n`,
      });

      setRes(result.data.choices[0].message.content);
      setIsLoading(false);
    } catch (error) {
      console.error("Firebase function invocation failed:", error);
    }
  };

  return (
    <Box className="App">
      <Box sx={{ flexGrow: 1 }} position={"sticky"} top={0} zIndex={10}>
        <AppBar position="sticky">
          <Toolbar>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h6" component="div">
                Pintar Kredit
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      <Container maxWidth="sm">
        {step === "start" && (
          <Box display="flex" flexDirection="column" gap={2} my={4}>
            <Typography variant="h5" gutterBottom>
              Isi data peminjam di bawah ini untuk mendapatkan analisis
            </Typography>
            <TextField
              fullWidth
              label="Pekerjaan"
              helperText="Contoh: Teller Bank"
              variant="outlined"
              value={dataPeminjam.pekerjaan}
              onChange={(e) => {
                setDataPeminjam((prev) => ({
                  ...prev,
                  pekerjaan: e.target.value,
                }));
              }}
            />
            <TextField
              fullWidth
              label="Jenis Usaha / Tempat Bekerja"
              helperText="Contoh: Bank"
              variant="outlined"
              value={dataPeminjam.jenis_usaha_or_tempat_bekerja}
              onChange={(e) => {
                setDataPeminjam((prev) => ({
                  ...prev,
                  jenis_usaha_or_tempat_bekerja: e.target.value,
                }));
              }}
            />
            <TextField
              fullWidth
              label="Penghasilan"
              helperText="Contoh: 1 juta per bulan"
              variant="outlined"
              value={dataPeminjam.penghasilan}
              onChange={(e) => {
                setDataPeminjam((prev) => ({
                  ...prev,
                  penghasilan: e.target.value,
                }));
              }}
            />
            <TextField
              fullWidth
              label="Pinjaman"
              variant="outlined"
              helperText="Contoh: 10 juta"
              value={dataPeminjam.pinjaman}
              onChange={(e) => {
                setDataPeminjam((prev) => ({
                  ...prev,
                  pinjaman: e.target.value,
                }));
              }}
            />
            <TextField
              fullWidth
              label="Lama Angsuran"
              helperText="Contoh: 24 bulan"
              variant="outlined"
              value={dataPeminjam.angsuran}
              onChange={(e) => {
                setDataPeminjam((prev) => ({
                  ...prev,
                  angsuran: e.target.value,
                }));
              }}
            />
            <ToggleButtonGroup
              color="primary"
              value={dataPeminjam.status}
              exclusive
              onChange={(e, value) => {
                setDataPeminjam((prev) => ({
                  ...prev,
                  status: value,
                }));
              }}
            >
              <ToggleButton value="kawin">Kawin</ToggleButton>
              <ToggleButton value="belum_kawin">Belum Kawin</ToggleButton>
            </ToggleButtonGroup>
            <TextField
              fullWidth
              label="Tanggungan"
              helperText="Contoh: 2 anak"
              variant="outlined"
              value={dataPeminjam.tanggungan}
              onChange={(e) => {
                setDataPeminjam((prev) => ({
                  ...prev,
                  tanggungan: e.target.value,
                }));
              }}
            />
            <TextField
              fullWidth
              label="Jaminan"
              helperText="Contoh: Tanah"
              variant="outlined"
              value={dataPeminjam.jaminan}
              onChange={(e) => {
                setDataPeminjam((prev) => ({
                  ...prev,
                  jaminan: e.target.value,
                }));
              }}
            />
            <TextField
              fullWidth
              label="Nilai Jaminan"
              helperText="Contoh: 10 juta"
              variant="outlined"
              value={dataPeminjam.nilai_jaminan}
              onChange={(e) => {
                setDataPeminjam((prev) => ({
                  ...prev,
                  nilai_jaminan: e.target.value,
                }));
              }}
            />
            <TextField
              fullWidth
              label="Tujuan Pinjaman"
              variant="outlined"
              helperText="Biaya kuliah"
              value={dataPeminjam.tujuan_pinjaman}
              onChange={(e) => {
                setDataPeminjam((prev) => ({
                  ...prev,
                  tujuan_pinjaman: e.target.value,
                }));
              }}
            />
            <TextField
              fullWidth
              helperText="Contoh: 1.5%"
              label="Bunga"
              variant="outlined"
              value={dataPeminjam.bunga}
              onChange={(e) => {
                setDataPeminjam((prev) => ({
                  ...prev,
                  bunga: e.target.value,
                }));
              }}
            />
            <TextField
              fullWidth
              label="Catatan"
              variant="outlined"
              value={dataPeminjam.catatan}
              onChange={(e) => {
                setDataPeminjam((prev) => ({
                  ...prev,
                  catatan: e.target.value,
                }));
              }}
            />
            <Button
              onClick={() => {
                setStep("finish");
                analyzeData();
              }}
              variant="contained"
              color="primary"
            >
              Analisis
            </Button>
          </Box>
        )}

        {step === "finish" && (
          <Box marginTop={4}>
            <Typography variant="h6" gutterBottom>
              Hasil analisis
            </Typography>
            {isLoading ? (
              <LinearProgress variant="determinate" value={loadingPercentage} />
            ) : (
              <>
                <TextField
                  variant="standard"
                  value={res}
                  multiline
                  rows={Math.floor(window.innerHeight / 35)}
                  fullWidth
                />
                <Box display={"flex"} flexDirection={"column"} mt={2} gap={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      navigator.clipboard.writeText(res);
                    }}
                  >
                    Salin
                  </Button>
                  <Button
                    onClick={analyzeData}
                    variant="contained"
                    color="secondary"
                  >
                    Analisis Ulang
                  </Button>
                  <Button onClick={() => setStep("start")}>Kembali</Button>
                </Box>
              </>
            )}
          </Box>
        )}
      </Container>
    </Box>
  );
}

export default App;
